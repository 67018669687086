import generatingCharacterImageAnimation from "./generatingCharacterImageAnimation";
import intervalManager from "./intervalManager";

const handleGenerateCharacterImage = async ({characterCreatorState, setCharacterCreatorState, userInfo, setIsDisabled}) =>{
    
    
    //Verify that user can pay for image generation
    try{
        const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
        const response = await fetch(`${API_BASE_URL}/validate-token-amount`,{
            method:'POST',
            headers:{
                'Content-Type':'application/json',
            },
            body:JSON.stringify({
                userInfo: userInfo,
                amount: 1
            })
        });
        const result = await response.json()
        if(result.message === 'Sufficient Token Amount'){
            //Continues     
        }
        else if(result.message === 'Not Enough Tokens'){
            document.getElementsByClassName('purchase-tokens-card')[0].style.display = 'block'
            document.getElementsByClassName('background-shades')[0].style.display = 'block'
            return
        }
        else{
            document.getElementsByClassName('sign-in-card')[0].style.display = 'block'
            document.getElementsByClassName('background-shades')[0].style.display = 'block'
            return
        }

    }catch(err){
        return
    }

    setIsDisabled(true) 

    let step = 0

    // Set Loading Animation   
    intervalManager.setInterval(()=>{
        if (step===4){
            step=0;
        }
        generatingCharacterImageAnimation({characterCreatorState:characterCreatorState, setCharacterCreatorState:setCharacterCreatorState, step:step})
        step++;
    }, 500)
    // Create Character
    try{
        const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
        const response = await fetch(`${API_BASE_URL}/create-new-character`, {
            method: 'POST',
            headers:{
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({prompt:characterCreatorState.age+"-year-old " + characterCreatorState.gender + ", " + characterCreatorState.ethnicity + ", with " + characterCreatorState.hair + " hair, wearing " + characterCreatorState.clothing, negativePrompt: characterCreatorState.featuresToAvoid}),
        });
        const result = await response.json();
        return(result)
        // After making this call, I wait for the websocket message to hit and the logic for that is in app.js
    } catch{
        return
    }
};

export default handleGenerateCharacterImage;