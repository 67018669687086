import { useNavigate } from "react-router-dom"
import handleNavigation from "./handleNavigate"
import createNewStoryboard from "./createNewStoryboard"

const NavigationButton = ({buttonTitle, storyboards, setStoryboards, setStoryboardState, isAuthenticated}) =>{
    const addButton = require('../Images/AddButton2.png')
    const navigate = useNavigate()

    if (buttonTitle === "New Storyboard"){
        return (<img alt="New Storyboard Button" onClick={()=>{if(isAuthenticated){createNewStoryboard(storyboards, setStoryboards, setStoryboardState); handleNavigation("/Storyboard", navigate)} else{ document.getElementsByClassName("sign-in-card")[0].style.display='block'}}} src={addButton}/>)
    }
    else if (buttonTitle === "New Character"){
        return (<img alt="New Character Button" onClick={()=>{if(isAuthenticated){handleNavigation("/CharacterCreation", navigate)} else{ document.getElementsByClassName("sign-in-card")[0].style.display='block'}}} src={addButton}/>)
    }
}

export default NavigationButton