import generatingImageAnimation from "./generatingImageAnimation";
import intervalManager from "./intervalManager";

const handleButtonClick = async ({prompt, imageIndex, setImageIndex, storyboardState, setStoryboardState, characters, userInfo, setIsDisabled}) =>{
    setImageIndex(imageIndex)
    //Validate user has enough tokens
    try{
        const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
        const response = await fetch(`${API_BASE_URL}/validate-token-amount`,{
            method:'POST',
            headers:{
                'Content-Type':'application/json',
            },
            body:JSON.stringify({
                userInfo: userInfo,
                amount: 3
            })
        });
        const result = await response.json()
        if(result.message === 'Sufficient Token Amount'){
            //Continues     
        }
        else if(result.message === 'Not Enough Tokens'){
            document.getElementsByClassName('purchase-tokens-card')[0].style.display = 'block'
            document.getElementsByClassName('background-shades')[0].style.display = 'block'
            return
        }
        else{
            document.getElementsByClassName('sign-in-card')[0].style.display = 'block'
            document.getElementsByClassName('background-shades')[0].style.display = 'block'
            return
        }

    }catch(err){
        console.error(err)
        return
    }

    setIsDisabled(true)

    let step = 0

    intervalManager.setInterval(()=>{
        if (step===4){
            step=0;
        }
        generatingImageAnimation({imageIndex:imageIndex, step:step, storyboardState, setStoryboardState})
        step++;
    }, 500)


    if(storyboardState.storyboard[imageIndex].selectedCharacter === 'None'){
        console.log('generating image without predefined character')
        try{
            const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
            const response = await fetch(`${API_BASE_URL}/generate-image`, {
                method: 'POST',
                headers:{
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({prompt:prompt}),
            });
            const result = await response.json();
            return(result)
        } catch (error){
            console.error('Error:', error)
        }
    }
    else{
        console.log('generating image with predefined character!')
        let characterObject = characters.find(item => item.name===storyboardState.storyboard[imageIndex].selectedCharacter)
        let characterImageId = characterObject.imageId
        let characterPrompt = characterObject.age+"-year-old " + characterObject.gender + ", " + characterObject.ethnicity + ", with " + characterObject.hair + " hair, wearing " + characterObject.clothing
        console.log(characterImageId)
        try{
            const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
            const response = await fetch(`${API_BASE_URL}/generate-image-with-character`, {
                method: 'POST',
                headers:{
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({prompt:prompt, imageId:characterImageId, characterPrompt: characterPrompt }),
            });
            const result = await response.json();
            return(result)
        } catch (error){
            console.error('Error:', error)
        }
    }
    
};

export default handleButtonClick;