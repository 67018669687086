const imageGenerating = require('../Images/image_loading.jpg')
const imageGenerating1 = require('../Images/image_loading_1.jpg')
const imageGenerating2 = require('../Images/image_loading_2.jpg')
const imageGenerating3 = require('../Images/image_loading_3.jpg')
const generatingImageAnimation = ({imageIndex, step, storyboardState, setStoryboardState}) =>{
    const tempStoryboardState = {...storyboardState}
    if (step === 0){
        tempStoryboardState.storyboard[imageIndex].image = imageGenerating
    }
    else if (step===1){
        tempStoryboardState.storyboard[imageIndex].image = imageGenerating1
    }
    else if (step===2){
        tempStoryboardState.storyboard[imageIndex].image = imageGenerating2
    }
    else if (step===3){
        tempStoryboardState.storyboard[imageIndex].image = imageGenerating3
    }
    setStoryboardState(tempStoryboardState)
}

export default generatingImageAnimation