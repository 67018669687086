import './Button.css'
const Button = ({id, buttonText, buttonFunction, className, isDisabled}) =>{

    return(
        <button disabled={isDisabled} id={id} className={`Button-Component ${className}`} onClick={buttonFunction}>
            <p className="Button-Text">
            {buttonText} 
            </p>
            </button>
    )
}

export default Button