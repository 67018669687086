const imageGenerating = require('../Images/character_image_loading.jpg')
const imageGenerating1 = require('../Images/character_image_loading_1.jpg')
const imageGenerating2 = require('../Images/character_image_loading_2.jpg')
const imageGenerating3 = require('../Images/character_image_loading_3.jpg')
const generatingCharacterImageAnimation = ({characterCreatorState, setCharacterCreatorState, step}) =>{
    const tempCharacterCreatorState = {...characterCreatorState}

    if (step === 0){
        tempCharacterCreatorState.characterPreview = imageGenerating
    }
    else if (step===1){
        tempCharacterCreatorState.characterPreview = imageGenerating1
    }
    else if (step===2){
        tempCharacterCreatorState.characterPreview = imageGenerating2
    }
    else if (step===3){
        tempCharacterCreatorState.characterPreview = imageGenerating3
    }
    setCharacterCreatorState(tempCharacterCreatorState)
}

export default generatingCharacterImageAnimation