import React from "react"
import handleButtonClick from "./handleButtonClick"
import handleAddImage from "./handleAddImage"
import DropdownMenu from "./DropdownMenu"
import Button from "./Button"

const StoryBoard = ({ setImageIndex, placeholderImage, storyboardState, setStoryboardState, characters, setCharacters, userInfo, windowWidth, isDisabled, setIsDisabled}) =>{
    let tdCounter = 0
    let imageCounter = 0
    let currentRow = []
    let tableRows = []
    let characterNames = []
    let offSetX = 0
    let offSetY = 0
    let isMouseDown = false
    let data = ""
    let targetPanel = 0
    let targetDropZone = 0
    let decoy
    const tokenIcon = require('../Images/TokensIcon.png')
    const settings_icon = require('../Images/settings-icon.png')

    characters.forEach(character =>{
        characterNames.push(character.name)
    })

    const GenerateImageButton = ({handleButtonClick, prompt, imageIndex, setImageIndex, storyboardState, setStoryboardState, characters}) =>{
        return(
            <div className='Button-Container2'> 
            <Button isDisabled={isDisabled} id={"generate-image-button-"+imageIndex} buttonText='Generate Image' className='Generate-Image-Button' buttonFunction={()=> handleButtonClick({prompt:prompt, imageIndex:imageIndex, setImageIndex:setImageIndex, storyboardState:storyboardState, setStoryboardState:setStoryboardState, characters:characters, userInfo: userInfo, setIsDisabled: setIsDisabled})}/>
              <div className='Token-Container'>
                <img alt='Tokens Icon' className='Tokens-Icon2' src={tokenIcon}/>
                <p className='Tokens-Amount2'>~ 3</p>
              </div>
          </div>            
        )
    }

    const handleDeletePanel = ({imageIndex, storyboardState, setStoryboardState}) =>{
        const tempStoryboardState = {...storyboardState}
        console.log(tempStoryboardState.storyboard)
        tempStoryboardState.storyboard.splice(imageIndex,1)
        setStoryboardState(tempStoryboardState)
    }
    
    const DeletePanelButton = ({ imageIndex, setImageIndex, storyboardState, setStoryboardState}) =>{
        return(
            
                <div className="settings-container">
                        <img alt="settings icon" className="settings-icon" src={settings_icon}/>
                        <div className="panel-settings">
                            <p id={"delete-image-button-"+imageIndex}  className="storyboard-setting" onClick={()=> handleDeletePanel({imageIndex:imageIndex, setImageIndex:setImageIndex, storyboardState:storyboardState, setStoryboardState:setStoryboardState})}>Delete Panel</p>
                        </div>
                </div>
                
            
        )
    }
    
    const handleChangePrompts = ({itemPrompt, index}) => {
        const tempStoryboardState = {...storyboardState};
        tempStoryboardState.storyboard[index].itemPrompt  = itemPrompt;
        console.log(storyboardState.storyboard[index].itemPrompt)
        setStoryboardState(tempStoryboardState);
        console.log(characters)
    }

    const handleChangeLabel = (label, index) =>{
        const tempStoryboardState = {...storyboardState};
        tempStoryboardState.storyboard[index].label = label;
        setStoryboardState(tempStoryboardState)
    }

    const handleChangeNotes = (notes, index) =>{
        const tempStoryboardState = {...storyboardState};
        tempStoryboardState.storyboard[index].notes = notes;
        setStoryboardState(tempStoryboardState)
    }

    const handleMouseMove = (event, id) =>{
        if(isMouseDown){
            
            decoy.style.position ='absolute'
            decoy.style.left = `${event.clientX - offSetX - 18}px`
            decoy.style.top = `${event.clientY - offSetY + 143}px`

            const dropzones = document.querySelectorAll('.dropZone');
            console.log(dropzones)
            dropzones.forEach(dropzone=>{
                
                const rec = dropzone.getBoundingClientRect();

                const inZone = (
                    event.clientX>rec.left &&
                    event.clientX<rec.right &&
                    event.clientY>rec.top &&
                    event.clientY<rec.bottom
                )
                
                if(inZone){
                    console.log("targetDropZone: "+targetDropZone)
                    console.log(targetPanel)
                    if (dropzone.id.substring(9)===targetPanel){
                        return
                    }
                    targetDropZone = dropzone.id.substring(9)
                    dropzone.parentElement.classList.add('over')
                    if(dropzones[targetDropZone-1]){
                        console.log('under')
                        dropzones[targetDropZone-1].parentElement.classList.add('under')
                    }
                    
                }
                else{
                    
                    if(dropzones[targetDropZone-1]){
                        if(dropzones[targetDropZone-1].parentElement.classList.contains('under')){
                            console.log('removed')
                            dropzones[targetDropZone-1].parentElement.classList.remove('under')
                        }
                    }
                    
                    dropzone.parentElement.classList.remove('over')
                    
                    
                }
            })
    
        }
        

    }

    const handleMouseDown = (event,id)=>{
        if(event.target.className === 'Storyboard-Panel' || event.target.className==='Storyboard-Image'){
            document.addEventListener('mousemove', (event,id)=>handleMouseMove(event,id))
            document.addEventListener('mouseup', (event,id)=>handleMouseUp(event, id))
            const imageCounter = event.target.id.substring(6)
            isMouseDown = true
            targetPanel = imageCounter
            targetDropZone = targetPanel
            event.preventDefault()
            console.log(decoy)
            const tempStoryboardState = {...storyboardState}
            data = JSON.stringify(tempStoryboardState.storyboard[event.target.id.substring(6)])
            const panelContainer = document.getElementById('Panel-'+imageCounter)
            offSetX = event.clientX - panelContainer.offsetLeft
            offSetY = event.clientY - panelContainer.offsetTop
            decoy = panelContainer.cloneNode(true)
            document.body.appendChild(decoy)
            decoy.style.pointerEvents='none'
            panelContainer.style.opacity=0
        }
       
    }

    const handleMouseUp  = (event, id) =>{
        if(isMouseDown){
            decoy.remove()
            const panelContainer = document.getElementById('Panel-'+targetPanel)
            panelContainer.style.opacity=100
            console.log(panelContainer)
            console.log("Target:" + targetDropZone)
            panelContainer.style.position ='unset'
            const draggedElement = JSON.parse(data);
            const dropzones = document.querySelectorAll('.dropZone');
            dropzones.forEach(dropzone=>{
                dropzone.parentElement.classList.remove('over')
                dropzone.parentElement.classList.remove('under')
            })
            const tempStoryboardState = {...storyboardState}
            const indexOfDropZone = targetDropZone
            const latterHalfOfArray = tempStoryboardState.storyboard.slice(indexOfDropZone)
            const formerHalfOfArray = tempStoryboardState.storyboard.slice(0,indexOfDropZone)
            const formerIndex = formerHalfOfArray.findIndex(item=>JSON.stringify(item)===JSON.stringify(draggedElement))
            const latterIndex = latterHalfOfArray.findIndex(item=>JSON.stringify(item) === JSON.stringify(draggedElement))
            console.log(formerHalfOfArray)
            console.log(latterHalfOfArray)
            if(formerIndex !== -1){
                formerHalfOfArray.splice(formerIndex,1)
            }
            else{
                latterHalfOfArray.splice(latterIndex,1)
            }
            tempStoryboardState.storyboard = formerHalfOfArray.concat(draggedElement, latterHalfOfArray);
            setStoryboardState(tempStoryboardState)
            
            isMouseDown = false
        }
        
    }

    const handleMouseEnter = (e) => {
        console.log('entered')
        e.target.parentElement.classList.add('over')
    }

    const handleMouseLeave = (e) =>{
        console.log('Left')
        e.target.parentElement.classList.remove('over')
    }

    let maxCounter = 3
    if(windowWidth<1250){
        maxCounter = 2
    }
    if(windowWidth<800){
        maxCounter = 1
    }
    
    storyboardState.storyboard.forEach(item => {
        if(tdCounter===maxCounter){
            tdCounter = 0
            tableRows.push(<div className="Storyboard-Rows">{currentRow}</div>)
            currentRow = []
        }
        currentRow.push(
            <div id={"Panel-Container-"+imageCounter} className="Panel-Container" >
                <div id={"dropZone-"+imageCounter} className="dropZone" onMouseEnter={e=>handleMouseEnter(e)} onMouseLeave={e=>handleMouseLeave(e)}></div>
                <div className="Storyboard-Panel" id={'Panel-'+imageCounter} onMouseDown={(e,id)=>handleMouseDown(e,id)} onMouseUp={(e,id)=>handleMouseUp(e,id)} onMouseMove={(e,id)=>handleMouseMove(e,id)}>
                <img alt="Panel" id={'image-'+imageCounter} className="Storyboard-Image" src={item.image}></img>
                <input type="text" id={'label-'+imageCounter} className="Storyboard-Input" placeholder="Label" value = {item.label} onChange={(e)=>handleChangeLabel(e.target.value, e.target.id.substring(6))}/>
                <textarea id={'input-'+imageCounter} className="Storyboard-Input" value={item.itemPrompt} placeholder="Shot (e.g. Wide-shot of a man drinking coffee)" onChange={(e)=>{handleChangePrompts({itemPrompt:e.target.value, index:e.target.id.substring(6)})}}/>
              <DropdownMenu id={1} storyboardState={storyboardState} setStoryboardState={setStoryboardState} menuItems ={characterNames} index={imageCounter}/>
              <textarea id={'notes-'+imageCounter} className="Storyboard-Input" placeholder="Notes" value = {item.notes} onChange={(e)=>handleChangeNotes(e.target.value, e.target.id.substring(6))}/>
                <div className="Panel-Buttons">
                <GenerateImageButton prompt={item.itemPrompt} handleButtonClick={handleButtonClick} imageIndex={imageCounter} setImageIndex={setImageIndex} storyboardState = {storyboardState} setStoryboardState = {setStoryboardState} characters = {characters}/>
                <DeletePanelButton prompt={item.itemPrompt} handleButtonClick={handleButtonClick} imageIndex={imageCounter} setImageIndex={setImageIndex} storyboardState = {storyboardState} setStoryboardState = {setStoryboardState} characters = {characters}/>
                </div>
                </div>
              </div>
        )
        tdCounter += 1;
        imageCounter +=1;
    });
    if(tdCounter<maxCounter){
        currentRow.push(<div className="Panel-Container"><div id={"dropZone-"+imageCounter} className="dropZone" ></div><div className="Storyboard-Panel Add-Image-Panel " onClick={()=>{handleAddImage({placeholderImage:placeholderImage, storyboardState:storyboardState, setStoryboardState:setStoryboardState})}}><p className="Add-Image-Button">+</p></div></div>)
        tableRows.push(<div className="Storyboard-Rows">{currentRow}</div>)
    }
    else{
        tableRows.push(<div className="Storyboard-Rows">{currentRow}</div>)
        tableRows.push(<div className="Storyboard-Rows"><div className="Panel-Container"><div id={"dropZone-"+imageCounter} className="dropZone" ></div><div className="Storyboard-Panel Add-Image-Panel " onClick={()=>{handleAddImage({placeholderImage:placeholderImage, storyboardState:storyboardState, setStoryboardState:setStoryboardState})}}><p className="Add-Image-Button">+</p></div></div></div>)
    }
    
    return(<div className="storyboard">{tableRows}</div>)
}

export default StoryBoard