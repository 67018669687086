import { useState, useRef } from "react";
import ListOfCharacters from "../Components/ListOfCharacters";
import ListOfStoryboards from "../Components/ListOfStoryboards";
import Masthead from "../Components/Masthead";
import NavigationButton from "../Components/NavigationButton";
import SignIn from "../Components/SignInCard";
import SignUp from "../Components/SignUpCard";
import './Home.css'
import ConfirmModal from "../Components/ConfirmModal";
import ForgotPasswordCard from "../Components/ForgotPasswordCard";
import PurchaseTokensCard from "../Components/PurchaseTokensCard";
import useInactivityTimeout from "../Utilities/useInactivityTimeout"
import Footer from "../Components/Footer";
import Button from "../Components/Button"

const Home = ({characters, setCharacters, storyboards, setStoryboards, setStoryboardState, signUpInfo, setSignUpInfo, isAuthenticated, setIsAuthenticated, userInfo, setUserInfo}) => {
  const homePageNonAuthImage = require('../Images/StoryboardGenerator_NonAuth_HomePage_Image1.png')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [modalFunctionParams, setModalFunctionParams] = useState()
  const [modalFunction, setModalFunction] = useState()
  const [tutorialIndex, setTutorialIndex] = useState(0)
  const mobileTutorial = [require('../Images/tutorial_images/Tutorial Mobile 1.webp'),require('../Images/tutorial_images/Tutorial Mobile 2.webp'),require('../Images/tutorial_images/Tutorial Mobile 3.webp'),require('../Images/tutorial_images/Tutorial Mobile 4.webp'),require('../Images/tutorial_images/Tutorial Mobile 5.webp'),require('../Images/tutorial_images/Tutorial Mobile 6.webp'),require('../Images/tutorial_images/Tutorial Mobile 7.webp'),require('../Images/tutorial_images/Tutorial Mobile 8.webp'),require('../Images/tutorial_images/Tutorial Mobile 9.webp')]
  const desktopTutorial = [require('../Images/tutorial_images/Tutorial Desktop 1.webp'),require('../Images/tutorial_images/Tutorial Desktop 2.webp'),require('../Images/tutorial_images/Tutorial Desktop 3.webp'),require('../Images/tutorial_images/Tutorial Desktop 4.webp'),require('../Images/tutorial_images/Tutorial Desktop 5.webp'),require('../Images/tutorial_images/Tutorial Desktop 6.webp'),require('../Images/tutorial_images/Tutorial Desktop 7.webp'),require('../Images/tutorial_images/Tutorial Desktop 8.webp'),require('../Images/tutorial_images/Tutorial Desktop 9.webp')]
  const targetSection = useRef(null);
  const greyCarrot = require('../Images/greyCarrot.png')
  useInactivityTimeout(21600000, 'cooppStoryboardGenerator_AuthToken');

  const handleScroll = () => {
    const offset = 120;
    const sectionPosition = targetSection.current.getBoundingClientRect().top + window.scrollY;
    
    window.scrollTo({
      top: sectionPosition - offset,
      behavior: 'smooth',
    });
  };

  const handleNextTutorialImage = (direction) =>{
    if(tutorialIndex === 0 && direction === 'left'){
      return
    }
    else if(tutorialIndex === desktopTutorial.length-1 && direction === 'right'){
      return
    }
    else if(direction == 'right'){
      setTutorialIndex(tutorialIndex+1)
    }
    else{
      setTutorialIndex(tutorialIndex-1)
    }
  }

  return (
    <div className="App">
      <Masthead isAuthenticated={isAuthenticated} signUpInfo={signUpInfo} setSignUpInfo = {setSignUpInfo} setIsAuthenticated = {setIsAuthenticated} userInfo={userInfo} setUserInfo={setUserInfo}/>
      <SignUp signUpInfo={signUpInfo} setSignUpInfo = {setSignUpInfo}/>
      <SignIn signUpInfo={signUpInfo} setSignUpInfo = {setSignUpInfo} setIsAuthenticated = {setIsAuthenticated}/>
      <ForgotPasswordCard signUpInfo={signUpInfo} setSignUpInfo = {setSignUpInfo} setIsAuthenticated = {setIsAuthenticated}/>
      <PurchaseTokensCard userInfo = {userInfo} />
      {isAuthenticated ? 
      <body className="App-body-home">
      <div className="background-shades" onClick={(e)=>{e.target.style.display='none'; document.getElementsByClassName("sign-in-card")[0].style.display='none'; document.getElementsByClassName("sign-up-card")[0].style.display='none'; document.getElementsByClassName("forgot-password-card")[0].style.display='none';document.getElementsByClassName("purchase-tokens-card")[0].style.display='none'; document.getElementsByClassName('sign-in-notification-message')[0].innerHTML=''; document.getElementsByClassName('sign-up-notification-message')[0].innerHTML=''; }}/>
      {isModalOpen ? <ConfirmModal message={modalMessage} onConfirm={()=>{modalFunction(modalFunctionParams); setIsModalOpen(false)}} onCancel={()=>setIsModalOpen(false)}/> : <p></p>}
        <div className="User-Dashboard">
          <div className="Storyboard-Dashboard">
            <div className="Dashboard-Header">
              <h3>Storyboards</h3>
              <NavigationButton buttonTitle="New Storyboard" setStoryboardState={setStoryboardState} storyboards={storyboards} setStoryboards={setStoryboards} isAuthenticated={isAuthenticated} />
            </div>
            <ListOfStoryboards storyboards={storyboards} setStoryboards={setStoryboards} setStoryboardState={setStoryboardState} setModalFunctionParams = {setModalFunctionParams} setModalFunction={setModalFunction} setModalMessage = {setModalMessage} setIsModalOpen={setIsModalOpen}/>
          </div>
          <div className="Characters-Dashboard">
            <div className="Dashboard-Header">
            <h3>Characters</h3>
            <NavigationButton buttonTitle="New Character" isAuthenticated={isAuthenticated}/>
            </div>
            <ListOfCharacters characters={characters} setCharacters={setCharacters} setModalFunctionParams = {setModalFunctionParams} setModalFunction={setModalFunction} setModalMessage = {setModalMessage} setIsModalOpen={setIsModalOpen} />
          </div>
        </div>
      </body> :
<body className="App-body-home">
<div className="background-shades" onClick={(e)=>{e.target.style.display='none'; document.getElementsByClassName("sign-in-card")[0].style.display='none'; document.getElementsByClassName("sign-up-card")[0].style.display='none'; document.getElementsByClassName('sign-in-notification-message')[0].innerHTML=''; document.getElementsByClassName('sign-up-notification-message')[0].innerHTML=''; }}/>
  <div className="nonauth-body">
  <div className="User-Dashboard-NotSignedIn">
    <div className="homepage-nonauth-leftHalf">
    <img alt="Collage of images, illustrating the potential of NXTVid's AI Storyboard Generator tool." className="homepage-nonauth-image" src={homePageNonAuthImage}/>
    </div>
    
    <div className="homepage-nonauth-righHalf">
      <h1 className="homepage-nonauth-title">Storyboard Generator</h1>
      <h2 className="homepage-nonauth-subtitle">Bring your ideas to life using NXTVid's Storyboard Generator.</h2>
      
      <div className='signup-signin-homepage'>
                <p className="Homepage-SignIn see-how-it-works-link" onClick={handleScroll}>See how it works.</p>
                <p className='Homepage-SignIn' onClick={()=>{document.getElementsByClassName('sign-in-card')[0].style.display='block';document.getElementsByClassName('background-shades')[0].style.display='flex';}}>Sign In</p>
                  <Button id="Homepage-SignUp" className = 'Homepage-SignUp' buttonFunction={()=>{document.getElementsByClassName('sign-up-card')[0].style.display='block';document.getElementsByClassName('background-shades')[0].style.display='flex';}} buttonText='Join'/>
                </div>
    </div>
    
  </div>
  <div className='tutorial-section' ref={targetSection}>
  <div className='leftButton' onMouseDown={()=>handleNextTutorialImage('left')}>
                    <img src={greyCarrot}/>
                </div>
                <img alt="Tutorial explaining how to use the AI storyboard generator" className='desktopTutorial' src={desktopTutorial[tutorialIndex]}/>
        <img alt="Tutorial explaining how to use the AI storyboard generator" className='mobileTutorial' src={mobileTutorial[tutorialIndex]}/>
                
                <div className='rightButton' onMouseDown={()=>handleNextTutorialImage('right')}>
                    <img src={greyCarrot}/>
                </div>
  </div>
  </div>
  
</body>
    
    }
     <footer>
      <Footer/>
      </footer> 
    </div>
  );
};

export default Home;
