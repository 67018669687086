import ProductDropdown from './ProductDropdown'
import './Masthead.css'

const Masthead = ({isAuthenticated, userInfo, setUserInfo}) =>{
    const logo = require('../Images/Logo.png')
    const joinButton = require('../Images/joinButton.png')
    const tokenIcon = require('../Images/TokensIcon.png')
    if(isAuthenticated){
      return(
        <header className="App-header">
        <img alt='NXTVid Logo' className="Logo" src={logo}/>
        <div className='NavBar'>
          <div className='NavBar-Item tokensContainer' onClick={() =>{document.getElementsByClassName('purchase-tokens-card')[0].style.display = 'block'; document.getElementsByClassName('background-shades')[0].style.display = 'block'}}>
          <img alt='tokens' src={tokenIcon} className='tokensIcon' />
          <p >{userInfo.tokens}</p>
          </div>
            <p className='NavBar-Item' id='myProjectsButton' onClick={()=>{window.location.href = "/"}}>My Projects</p>
            <div className='NavBar-Item' id='product-title'>
                <ProductDropdown/>
              </div>
              <div className='profileImage' onMouseEnter={()=>{document.getElementById('sign-out-button').style.display= 'flex';}} onMouseLeave={()=>{document.getElementById('sign-out-button').style.display= 'none';}}>
                <p>{userInfo.firstName[0] + userInfo.lastName[0]}</p>
                <div className='sign-out-button' id='sign-out-button' onClick={()=>{localStorage.removeItem('cooppStoryboardGenerator_AuthToken'); window.location.reload()}}>
                  <p>Sign Out</p>
                </div>
              </div>
          </div>
          <div className='mobile-NavBar'>
              <p className='hamburger-menu' onClick={()=>{document.getElementsByClassName('sliding-menu')[0].style.display==="flex" ? document.getElementsByClassName('sliding-menu')[0].style.display="none" : document.getElementsByClassName('sliding-menu')[0].style.display="flex"}}>&#9776;</p>
              <div className='sliding-menu'>
                <div className='mobile-nav-left'>
                <div className='profileImage' onMouseEnter={()=>{document.getElementById('sign-out-button-mobile').style.display= 'flex';}} onMouseLeave={()=>{document.getElementById('sign-out-button-mobile').style.display= 'none';}}>
                <p>{userInfo.firstName[0] + userInfo.lastName[0]}</p>
                <div className='sign-out-button' id='sign-out-button-mobile' onClick={()=>{localStorage.removeItem('cooppStoryboardGenerator_AuthToken'); window.location.reload()}}>
                  <p>Sign Out</p>
                </div>
              </div>
              <p className='NavBar-Item vanish-2' id='myProjectsButton' onClick={()=>{window.location.href = "/"}}>My Projects</p>
              <div className='NavBar-Item tokensContainer'>
                <img alt='tokens' src={tokenIcon} className='tokensIcon' onClick={() =>{document.getElementsByClassName('purchase-tokens-card')[0].style.display = 'block'; document.getElementsByClassName('background-shades')[0].style.display = 'block'}}/>
                <p>{userInfo.tokens}</p>
              </div>
                </div>
                <div className='mobile-nav-right'>
                <div className='NavBar-Item vanish-1' id='product-title'>
                  <ProductDropdown/>
                </div>
                </div>
              
                  </div>
            </div>
          </header>
      )
    }
    else{
      return(
        <header className="App-header">
        <img alt='NXTVid Logo' className="Logo" src={logo}/>
        <div>
            <div className='NavBar'>
              <div className='NavBar-Item' id='product-title'>
                <ProductDropdown/>
              </div>
              <p className='NavBar-Item' onClick={()=>{document.getElementsByClassName('sign-in-card')[0].style.display='block';document.getElementsByClassName('background-shades')[0].style.display='flex';}}>Sign In</p>
              <img alt='Join Button' id="masthead-joinButton" className='masthead-button' src={joinButton} onClick={()=>{document.getElementsByClassName('sign-up-card')[0].style.display='block';document.getElementsByClassName('background-shades')[0].style.display='flex';}}/>
            </div>
            <div className='mobile-NavBar'>
              <p className='hamburger-menu' onClick={()=>{document.getElementsByClassName('sliding-menu')[0].style.display==="flex" ? document.getElementsByClassName('sliding-menu')[0].style.display="none" : document.getElementsByClassName('sliding-menu')[0].style.display="flex"}}>&#9776;</p>
              <div className='sliding-menu'>
                <div className='NavBar-Item vanish-1' id='product-title'>
                  <ProductDropdown/>
                </div>
                <div className='signup-signin'>
                <p className='NavBar-Item' onClick={()=>{document.getElementsByClassName('sign-in-card')[0].style.display='block';document.getElementsByClassName('background-shades')[0].style.display='flex';}}>Sign In</p>
                  <img alt="Join Button" id="masthead-joinButton" className='masthead-button' src={joinButton} onClick={()=>{document.getElementsByClassName('sign-up-card')[0].style.display='block';document.getElementsByClassName('background-shades')[0].style.display='flex';}}/>
                </div>
                  </div>
            </div>
          </div>
          </header>
      )
    }   
}

export default Masthead