import "./Footer.css"
const Footer = () =>{
    const logo = require("../Images/Logo_Wide_All_Black.png")
    return(
        <div className="Footer">
            <div className="Footer-Top-Half">
            <div className="Footer-Section NXTVid">
                <h3 className="Footer-Section-Header">NXTVid</h3>
                <p className="Footer-Section-P Subtitle clickable" onClick={()=>window.open('https://nxtvid.io/', '_blank')}>Video Production Made Simple</p>
                <p className="Footer-Section-P"/>
                <p className="Footer-Section-P clickable" onClick={()=>window.open('https://nxtvid.io/about-us/', '_blank')}>About</p>
                <p className="Footer-Section-P clickable" onClick={()=>window.open('https://nxtvid.io/', '_blank')}>Marketplace</p>
            </div>
            <div className="Footer-Section Businesses">
             <h3 className="Footer-Section-Header">Businesses</h3>
             <p className="Footer-Section-P"/>
             <p className="Footer-Section-P"/>
             <p className="Footer-Section-P clickable" onClick={()=>window.open('https://nxtvid.io/production-studios/?type=production-studios', '_blank')}>Search for Videographers</p>
             <p className="Footer-Section-P clickable" onClick={()=>window.open('https://chatgpt.com/g/g-X0Aulu9jp-nxtvid-rfp-generator', '_blank')}>RFP Generator</p>
             <p className="Footer-Section-P clickable" onClick={()=>window.open('https://chatgpt.com/g/g-riOc5zEQF-nxtvid-shot-list-generator', '_blank')}>Shot List Generator</p>
            </div>
            <div className="Footer-Section Videographers" >
                <h3 className="Footer-Section-Header" >Videographers</h3>
                <p className="Footer-Section-P"/>
                <p className="Footer-Section-P"/>
                <p className="Footer-Section-P clickable" onClick={()=>window.open('https://nxtvid.io/nxtvid-membership-benefits/', '_blank')}>Apply to be a NXTVid Videographer</p>
                <p className="Footer-Section-P clickable" onClick={()=>window.open('https://chatgpt.com/g/g-riOc5zEQF-nxtvid-shot-list-generator', '_blank')}>Shot List Generator</p>
                <p className="Footer-Section-P clickable" onClick={()=>window.open('https://chatgpt.com/g/g-lhrsqyBL2-nxtvid-proposal-generator', '_blank')}>Proposal Generator</p>
            </div>
            <div className="Footer-Section">
                <h3 className="Footer-Section-Header">Support</h3>
                <p className="Footer-Section-P"/>
                <p className="Footer-Section-P"/>
                <p className="Footer-Section-P clickable" onClick={()=>window.open('mailto:support@nxtvid.io?subject=NXTVid%20App%20Feedback', '_blank')}>Contact</p>
                <p className="Footer-Section-P clickable" onClick={()=>window.open('https://nxtvid.io/privacy-policy-2/', '_blank')}>Terms of Use</p>
                <p className="Footer-Section-P clickable" onClick={()=>window.open('https://nxtvid.io/privacy-policy-2/', '_blank')}>Privacy Policy</p>
            </div>
            </div>
            <div className="Footer-Bottom-Half">
                <img className="grey-logo" src={logo} alt="NXTVid Logo" onClick={()=>window.open('https://nxtvid.io/', '_blank')}/>
                <p className="copywrite">© 2025 NXTVid, Inc.</p>
            </div>
        </div>
    )
}

export default Footer