import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const useInactivityTimeout = (timeoutDuration, tokenName) => { 
  const navigate = useNavigate();
  const timeoutRef = useRef(null);


  const handleLogout = useCallback(() => {
    // Clear the timeout
    clearTimeout(timeoutRef.current);
    localStorage.removeItem(tokenName)
    navigate('/');
  },[navigate, tokenName]);
  
  const resetTimeout = useCallback(() => {
    // Clear the existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    
    // Set a new timeout
    timeoutRef.current = setTimeout(() => {
      handleLogout();
    }, timeoutDuration);
  }, [timeoutRef, handleLogout, timeoutDuration]);

  

  const handleActivity = useCallback(() => {
    resetTimeout();
  }, [resetTimeout]);

  useEffect(() => {
    // Set up event listeners for user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    // Initialize the timeout when the component mounts
    resetTimeout();

    // Cleanup on unmount
    return () => {
      clearTimeout(timeoutRef.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [handleActivity, resetTimeout]);

  return null;
};

export default useInactivityTimeout;
