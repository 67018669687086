import { useEffect, useState } from "react"
import Masthead from "../Components/Masthead"
import SignIn from "../Components/SignInCard"
import SignUp from "../Components/SignUpCard"
import StoryBoard from "../Components/StoryBoard"
import exportToPDF from "../Components/exportToPDF"
import saveStoryboard from "../Components/saveStoryboard"
import './StoryBoardPage.css'
import { useNavigate } from "react-router-dom"
import handleNavigation from '../Components/handleNavigate';
import ConfirmModal from "../Components/ConfirmModal"
import AlertModal from "../Components/AlertModal"
import ForgotPasswordCard from "../Components/ForgotPasswordCard"
import PurchaseTokensCard from "../Components/PurchaseTokensCard"
import useInactivityTimeout from "../Utilities/useInactivityTimeout"

const StoryBoardPage = ({images,prompts,setPrompts,setImageIndex,imageIndex,setImages,placeholderImage,storyboardState, setStoryboardState, characters, setCharacters, storyboards, setStoryboards, signUpInfo, setSignUpInfo, isAuthenticated, setIsAuthenticated, userInfo, setUserInfo, isDisabled, setIsDisabled})=>{

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const backToDashboardButton = require('../Images/BackToDashboardButton.png')
  const export_icon = require('../Images/export-icon.png')
  const save_icon = require('../Images/save-icon.png')
  const back_icon = require('../Images/back-icon.png')
  const navigate = useNavigate();
  useInactivityTimeout(21600000, 'cooppStoryboardGenerator_AuthToken');

  const handleResize = () =>{
    setWindowWidth(window.innerWidth)
  }

  useEffect(()=>{
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);  // Clean up the event listener
    };
  }, [])

  const handleChangeProjectTitle = (value) =>{
    const tempStoryboardState = {...storyboardState}
    tempStoryboardState.projectTitle = value
    console.log(tempStoryboardState)
    setStoryboardState(tempStoryboardState)
  }


  const handleConfirmLeavePage = () =>{
    setIsModalOpen(true)
  }

    return(
        <div className="App">
      <Masthead isAuthenticated={isAuthenticated} signUpInfo={signUpInfo} setSignUpInfo = {setSignUpInfo} setIsAuthenticated = {setIsAuthenticated} userInfo={userInfo} setUserInfo={setUserInfo}/>
      <SignUp signUpInfo={signUpInfo} setSignUpInfo = {setSignUpInfo}/>
      <SignIn signUpInfo={signUpInfo} setSignUpInfo = {setSignUpInfo} setIsAuthenticated = {setIsAuthenticated}/>
      <ForgotPasswordCard signUpInfo={signUpInfo} setSignUpInfo = {setSignUpInfo} setIsAuthenticated = {setIsAuthenticated}/>
      <PurchaseTokensCard userInfo = {userInfo} />
      <body className = "App-body">
      <div className="background-shades" onClick={(e)=>{e.target.style.display='none'; document.getElementsByClassName("sign-in-card")[0].style.display='none'; document.getElementsByClassName("sign-up-card")[0].style.display='none'; document.getElementsByClassName("forgot-password-card")[0].style.display='none';document.getElementsByClassName("purchase-tokens-card")[0].style.display='none'; document.getElementsByClassName('sign-in-notification-message')[0].innerHTML=''; document.getElementsByClassName('sign-up-notification-message')[0].innerHTML=''; }}/>
      <input className="storyboardPage-ProjectTitle" type="text" value={storyboardState.projectTitle} onChange={(e) => handleChangeProjectTitle(e.target.value)}/>
      {isModalOpen ? <ConfirmModal message={"Are you sure you want to leave this page? Your work won't automatically be saved."} onConfirm={()=>handleNavigation("/", navigate)} onCancel={()=>setIsModalOpen(false)}/> : <p></p>}
      {isAlertOpen ? <AlertModal message={"Your storyboard has been saved!"} onConfirm={()=>setIsAlertOpen(false)}/> : <p></p>}
        <button src={backToDashboardButton} className='backToDashboardButton-Storyboard' onClick={()=>handleConfirmLeavePage()}>{windowWidth > 357 ? "Back to Dashboard" : <img className="back_icon" alt="Back to Dashboard Button" src={back_icon}/>}</button>
        <button className="Save-Storyboard-Button" onClick={()=>{saveStoryboard(storyboardState, storyboards, setStoryboards); setIsAlertOpen(true)}}>{windowWidth > 1249 ? 'Save Storyboard' : <img className='save_icon' alt="Save button" src={save_icon} />}</button>
        <button className="Export-Storyboard-Button" onClick={()=>exportToPDF(storyboardState)}>{windowWidth > 1249 ? 'Export Storyboard' : <img className='export_icon' alt="Export button" src={export_icon} />}</button>
        <StoryBoard images={images} prompts={prompts} setPrompts = {setPrompts} setImageIndex = {setImageIndex} imageIndex={imageIndex} setImages = {setImages} placeholderImage={placeholderImage} storyboardState = {storyboardState} setStoryboardState = {setStoryboardState} characters={characters} setCharacters={setCharacters} userInfo = {userInfo} windowWidth={windowWidth} isDisabled={isDisabled} setIsDisabled={setIsDisabled}/>
      </body>
    </div>
    )
}

export default StoryBoardPage