const saveStoryboard = (storyboardState, storyboards, setStoryboards) =>{

    const saveToDb = async (allStoryboards) =>{
        const token = localStorage.getItem('cooppStoryboardGenerator_AuthToken')
        try{
            const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
            const response = await fetch(`${API_BASE_URL}/saveStoryboard`, {
                method:'POST',
                headers:{
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify({allStoryboards, token}),
            });
            const result = await response.json();
            console.log(result)
        } catch(error){
            console.error('Error: ', error)
        }
    }

    const index = storyboards.findIndex(item=>item.projectId === storyboardState.projectId)
    const tempStoryboards = [...storyboards]
    if(index!== -1){
        tempStoryboards[index] = {...storyboardState}
    }
    else{
        tempStoryboards.push({...storyboardState})
    }
    saveToDb(tempStoryboards)
    setStoryboards(tempStoryboards)
}

export default saveStoryboard