import './SignInCard.css'

const SignUp = ({signUpInfo, setSignUpInfo}) => {

    const exitButton = require('../Images/exit_button.png')

    const newRegistration = async () => {
        if(signUpInfo.firstName === '' || signUpInfo.lastName ==='' || signUpInfo.email==='' || signUpInfo.password===""){
            document.getElementsByClassName('sign-up-notification-message')[0].style.color='#DF7C52'
            document.getElementsByClassName('sign-up-notification-message')[0].innerHTML='Missing required information.';
            return
        }
        if(signUpInfo.password.length<6){
            document.getElementsByClassName('sign-up-notification-message')[0].style.color='#DF7C52'
            document.getElementsByClassName('sign-up-notification-message')[0].innerHTML='Password must be at least 6 characters long.';
            return
        }

        if(!signUpInfo.email.includes('@') || !signUpInfo.email.includes(".")){
            document.getElementsByClassName('sign-up-notification-message')[0].style.color='#DF7C52'
            document.getElementsByClassName('sign-up-notification-message')[0].innerHTML='Please use a valid email address.';
            return
        }

        try{
            const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
            const response = await fetch(`${API_BASE_URL}/registerNewUser`, {
                method:'POST',
                headers:{
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify({ firstName:signUpInfo.firstName, lastName:signUpInfo.lastName, company:signUpInfo.company, email:signUpInfo.email, password:signUpInfo.password }),
            });
            const result = await response.json();
            if(JSON.stringify(result)===`{"message":"Server error"}`){
                document.getElementsByClassName('sign-up-notification-message')[0].style.color='#DF7C52'
                document.getElementsByClassName('sign-up-notification-message')[0].innerHTML='Could not access the server. Please try again in a few minutes.';
                return
            }
            if(JSON.stringify(result)===`{"message":"A user with that email already exists"}`){
                document.getElementsByClassName('sign-up-notification-message')[0].style.color='#DF7C52'
                document.getElementsByClassName('sign-up-notification-message')[0].innerHTML=result.message;
                return
            }
            if(JSON.stringify(result)===`{"message":"User registered successfully"}`){
                document.getElementsByClassName('sign-up-notification-message')[0].innerHTML='';
                document.getElementsByClassName('sign-in-notification-message')[0].style.color='#6DBF67'
                document.getElementsByClassName('sign-in-notification-message')[0].innerHTML='Sign up successful!';
                document.getElementsByClassName('sign-up-card')[0].style.display='none'
                document.getElementsByClassName('sign-in-card')[0].style.display='block'
            }
            
        } 
        catch(error){
            console.error('Error: ', error)
        }
    }

    const handleUserChanges = (field, value) =>{
        const tempSignUpInfo = {...signUpInfo}
        tempSignUpInfo[field] = value
        setSignUpInfo(tempSignUpInfo)
        console.log(tempSignUpInfo)
    }

    return(
            <div className="sign-up-card">
            <div className="sign-up-card-header">
                <h4 className='sign-up-card-title'>Sign up</h4>
                <img alt='Exit Button' className='exit-button' src={exitButton} onClick={()=>{document.getElementsByClassName("sign-up-card")[0].style.display ="none";document.getElementsByClassName("background-shades")[0].style.display ="none"; document.getElementsByClassName('sign-up-notification-message')[0].innerHTML='';document.getElementsByClassName('sign-in-notification-message')[0].innerHTML='';}}/>
                <p className="sign-up-card-details">Already have an account?</p>
                <p className="sign-up-card-link" onClick={()=>{document.getElementsByClassName("sign-in-card")[0].style.display ="block"; document.getElementsByClassName("sign-up-card")[0].style.display ="none"; document.getElementsByClassName('sign-up-notification-message')[0].innerHTML=''; document.getElementsByClassName('sign-in-notification-message')[0].innerHTML='';}}>Sign in.</p>
                <p className='sign-up-notification-message'></p>
            </div>
            <div className="sign-up-input-section-first-name">
                <p className='sign-up-input-label'>First Name*</p>
                <input className="sign-up-input" type="text" value={signUpInfo.firstName} onChange={(e)=>handleUserChanges('firstName', e.target.value)}/>
            </div>
            <div className="sign-up-input-section-last-name">
                <p className='sign-up-input-label'>Last Name*</p>
                <input className="sign-up-input" type="text" value={signUpInfo.lastName} onChange={(e)=>handleUserChanges('lastName', e.target.value)}/>
            </div>
            <div className="sign-up-input-section-company">
                <p className='sign-up-input-label'>Company</p>
                <input className="sign-up-input" type="text" value={signUpInfo.company} onChange={(e)=>handleUserChanges('company', e.target.value)}/>
            </div>
            <div className="sign-up-input-section-email">
                <p className='sign-up-input-label'>Email*</p>
                <input className="sign-up-input" type="text" value={signUpInfo.email} onChange={(e)=>handleUserChanges('email', e.target.value.toLowerCase())}/>
            </div>
            <div className="sign-up-input-section-password">
                <p className='sign-up-input-label'>Password*</p>
                <input className="sign-up-input" type="password" value={signUpInfo.password} onChange={(e)=>handleUserChanges('password', e.target.value)}/>
            </div>
            <button className='sign-up-button' onClick={()=>newRegistration()}>Sign Up</button>
        </div>
        
    )
}

export default SignUp