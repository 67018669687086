import './ConfirmModal.css'

const ConfirmModal = ({onConfirm, onCancel, message}) =>{
    return(
      <div className = 'modal'>
        <p>{message}</p>
        <button onClick={onConfirm}>OK</button>
        <button onClick={onCancel}>Cancel</button>
      </div>
    )
  } 

  export default ConfirmModal