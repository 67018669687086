import Masthead from '../Components/Masthead';
import SignIn from '../Components/SignInCard';
import SignUp from '../Components/SignUpCard';
import handleUpdateCharacterCreatorState from '../Components/handleUpdateCharacterCreatorState';
import './CharacterCreationPage.css'
import handleSaveImage from "../Components/handleSaveImage";
import handleGenerateCharacterImage from "../Components/handleGenerateCharacterImage";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import handleNavigation from '../Components/handleNavigate';
import ConfirmModal from '../Components/ConfirmModal';
import AlertModal from '../Components/AlertModal';
import ForgotPasswordCard from '../Components/ForgotPasswordCard';
import PurchaseTokensCard from '../Components/PurchaseTokensCard';
import useInactivityTimeout from "../Utilities/useInactivityTimeout"
import Button from '../Components/Button';

const CharacterCreationPage = ({ setCharacters, characters, characterCreatorState, setCharacterCreatorState, signUpInfo, setSignUpInfo, isAuthenticated, setIsAuthenticated, userInfo, setUserInfo, isDisabled, setIsDisabled }) => {
  
  const backToDashboardButton = require('../Images/BackToDashboardButton.png')
  const saveCharacterButton = require('../Images/SaveCharacterButton.png')
  const [isToggled, setIsToggled] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const tokenIcon = require('../Images/TokensIcon.png')
  const navigate = useNavigate();
  useInactivityTimeout(21600000, 'cooppStoryboardGenerator_AuthToken');

  const handleToggle = () =>{
    const tempIsToggled = !isToggled
    setIsToggled(!isToggled)
    if(tempIsToggled){
      document.getElementsByClassName('Character-Creator-Output-Section')[0].style.display = 'block'
      document.getElementsByClassName('Character-Creator-Input-Section')[0].style.display = 'none'
    }
    else{
      document.getElementsByClassName('Character-Creator-Output-Section')[0].style.display = 'none'
      document.getElementsByClassName('Character-Creator-Input-Section')[0].style.display = 'block'
    }
  }

  const SaveCharacterButton = ({setCharacters, characters, characterCreatorState, setCharacterCreatorState}) => {
    return(
            <img alt='Save Character Button' id="saveCharacterButton" src={saveCharacterButton} className='saveCharacterButton' onClick={()=> {handleSaveImage({setCharacters: setCharacters, characters:characters, characterCreatorState:characterCreatorState, setCharacterCreatorState:setCharacterCreatorState}); setIsAlertOpen(true)}}/>
        )    
  }  

  const GenerateCharacterImageButton = ({characterCreatorState, setCharacterCreatorState, userInfo}) =>{
    return(
            <div className='Button-Container'> 
              <Button isDisabled={isDisabled} id='generateCharacterImageButton' buttonText='Generate Character' className='generateCharacterImageButton' buttonFunction={()=> {handleToggle(); handleGenerateCharacterImage({characterCreatorState:characterCreatorState, setCharacterCreatorState:setCharacterCreatorState, userInfo:userInfo, setIsDisabled:setIsDisabled})}}/>
                <div className='Token-Container'>
                  <img alt='Tokens Icon' className='Tokens-Icon' src={tokenIcon}/>
                  <p className='Tokens-Amount'>~ 1</p>
                </div>
            </div>
           
    )
}


  const handleConfirmLeavePage = () =>{
    setIsModalOpen(true)
  }
  
  
  return (
      <div className="App">
        <Masthead isAuthenticated={isAuthenticated} signUpInfo={signUpInfo} setSignUpInfo = {setSignUpInfo} setIsAuthenticated = {setIsAuthenticated} userInfo={userInfo} setUserInfo={setUserInfo}/>
      <SignUp signUpInfo={signUpInfo} setSignUpInfo = {setSignUpInfo}/>
      <SignIn signUpInfo={signUpInfo} setSignUpInfo = {setSignUpInfo} setIsAuthenticated = {setIsAuthenticated}/>
      <ForgotPasswordCard signUpInfo={signUpInfo} setSignUpInfo = {setSignUpInfo} setIsAuthenticated = {setIsAuthenticated}/>
      <PurchaseTokensCard userInfo = {userInfo} />
      <div className="App-body">
          <div className="background-shades" onClick={(e)=>{e.target.style.display='none'; document.getElementsByClassName("sign-in-card")[0].style.display='none'; document.getElementsByClassName("sign-up-card")[0].style.display='none'; document.getElementsByClassName("forgot-password-card")[0].style.display='none';document.getElementsByClassName("purchase-tokens-card")[0].style.display='none'; document.getElementsByClassName('sign-in-notification-message')[0].innerHTML=''; document.getElementsByClassName('sign-up-notification-message')[0].innerHTML=''; }}/>
        {isModalOpen ? <ConfirmModal message={"Are you sure you want to leave this page? Your work won't automatically be saved."} onConfirm={()=>handleNavigation("/", navigate)} onCancel={()=>setIsModalOpen(false)}/> : <p></p>}
        {isAlertOpen ? <AlertModal message={"Your character has been saved!"} onConfirm={()=>setIsAlertOpen(false)}/> : <p></p>}
        <img alt= "Back to Dashboard Button" src={backToDashboardButton} className='backToDashboardButton' onClick={()=>handleConfirmLeavePage()}/>
        <SaveCharacterButton setCharacters={setCharacters} characters={characters} characterCreatorState={characterCreatorState} setCharacterCreatorState={setCharacterCreatorState}/>
        <div className="Character-Creator">
            <div className="Character-Creator-Input-Section">
            <h2 className='Character-Creator-Header'>Character Creator</h2>
              <div className="Character-Creator-Input-Section-Div">
                <div className="Character-Name-Input">
                  <label htmlFor="characterNameInput">Character Name</label>
                  <input type="text" className='characterInput' placeholder='e.g., Harold' id="characterNameInput" value={characterCreatorState.name} onChange={(e)=>handleUpdateCharacterCreatorState('name', e.target.value, characterCreatorState, setCharacterCreatorState)}/>
                </div>
                <div className="Character-Age-Gender-Ethnicity-Input">
                  <div className="Character-Age-Input">
                    <label htmlFor="characterAgeInput">Age</label>
                    <input type="text" className='characterInput' placeholder='e.g., 35' id="characterAgeInput" value={characterCreatorState.age} onChange={(e)=>handleUpdateCharacterCreatorState('age', e.target.value, characterCreatorState, setCharacterCreatorState)} />
                  </div>
                  <div className="Character-Gender-Input">
                    <label htmlFor="characterGenderInput">Gender</label>
                    <input type="text" className='characterInput' placeholder='e.g., Female' id="characterGenderInput" value={characterCreatorState.gender} onChange={(e)=>handleUpdateCharacterCreatorState('gender', e.target.value, characterCreatorState, setCharacterCreatorState)}  />
                  </div>
                  <div className="Character-Ethnicity-Input">
                    <label htmlFor="characterEthnicityInput">Ethnicity</label>
                    <input type="text" className='characterInput' placeholder='e.g., Filipino' id="characterEthnicityInput" value={characterCreatorState.ethnicity} onChange={(e)=>handleUpdateCharacterCreatorState('ethnicity', e.target.value, characterCreatorState, setCharacterCreatorState)}  />
                  </div>
                </div>
                <div className="Character-Hair-Input">
                  <label htmlFor="characterHairInput">Hair</label>
                  <input type="text" className='characterInput' placeholder='e.g., shoulder-length, purple' id="characterHairInput" value={characterCreatorState.hair} onChange={(e)=>handleUpdateCharacterCreatorState('hair', e.target.value, characterCreatorState, setCharacterCreatorState)} />
                </div>
                <div className="Character-Clothing-Input">
                  <label htmlFor="characterClothingInput">Clothing</label>
                  <textarea rows="4" type="text" className='characterInput' placeholder='e.g., faux-leather jacket and swim trunks' id="characterClothingInput" value={characterCreatorState.clothing} onChange={(e)=>handleUpdateCharacterCreatorState('clothing', e.target.value, characterCreatorState, setCharacterCreatorState)} />
                </div>
                <div className="Character-Negative-Input">
                  <label htmlFor="characterNegativeInput">Features to Avoid</label>
                  <textarea rows="4" type="text" className='characterInput' placeholder='e.g., wrinkles, beard' id="characterNegativeInput" value={characterCreatorState.featuresToAvoid} onChange={(e)=>handleUpdateCharacterCreatorState('featuresToAvoid', e.target.value, characterCreatorState, setCharacterCreatorState)} />
                </div>
                
                <GenerateCharacterImageButton characterCreatorState = {characterCreatorState} setCharacterCreatorState={setCharacterCreatorState} userInfo={userInfo} />
                
              </div>
            </div>
            <div className="Character-Creator-Output-Section">
              <img
                id="characterCreatorOutputPreview"
                className="Character-Creator-Output-Preview"
                src={characterCreatorState.characterPreview}
                alt="Character Preview"
              />
            </div>
            <div className={`toggle-container ${isToggled ? 'toggled' : ''}`} id='toggle-container' onClick={handleToggle}>
              <div className='toggle-button'>
              </div>
            </div>
            <p className='creator-label'>Creator</p>
            <p className='preview-label'>Preview</p>
          </div>
        </div>
      </div>
    );
  };
  
  export default CharacterCreationPage;
  