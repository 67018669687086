import { useNavigate } from "react-router-dom"
import handleNavigation from "./handleNavigate"

const ListOfStoryboards = ({storyboards, setStoryboards, setStoryboardState, setModalFunctionParams, setModalMessage, setIsModalOpen, setModalFunction}) =>{
    let storyboardList = []
    const navigate = useNavigate()
    const edit_icon = require('../Images/edit-icon.png')
    const settings_icon = require('../Images/settings-icon.png')
    const placeholderImage = require('../Images/image_generation_placeholder.jpg')

    const handleDeleteStoryboard = (selectedStoryboards) =>{
        const saveToDb = async (allStoryboards) =>{
            const token = localStorage.getItem('cooppStoryboardGenerator_AuthToken')
            try{
                const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
                const response = await fetch(`${API_BASE_URL}/saveStoryboard`, {
                    method:'POST',
                    headers:{
                        'Content-Type': 'application/json',
                    },
                    body:JSON.stringify({allStoryboards, token}),
                });
                const result = await response.json();
                console.log(result)
            } catch(error){
                console.error('Error: ', error)
            }
        }
    
        const tempStoryboards = [...selectedStoryboards]
    console.log(tempStoryboards)
    saveToDb(tempStoryboards)
    setStoryboards(tempStoryboards)
        
    }


    storyboards.map(storyboard =>{
            const panel1 = storyboard.storyboard[0] ? storyboard.storyboard[0].image : placeholderImage
            const panel2 = storyboard.storyboard[1] ? storyboard.storyboard[1].image : placeholderImage
            const panel3 = storyboard.storyboard[2] ? storyboard.storyboard[2].image : placeholderImage
            storyboardList.push(
            <div className="storyboardCard" id={"storyboard-" + storyboard.projectId}>
                <div className="storyboardCard-details">
                    <div className="project-title-container">
                        <p className="storyboardCard-name">{storyboard.projectTitle}</p>
                        <div className="settings-container">
                        <img alt="settings icon" className="settings-icon" src={settings_icon}/>
                        <div className="storyboard-settings">
                            <p className="storyboard-setting" onClick={()=>{setModalFunction(()=>handleDeleteStoryboard);setModalFunctionParams(storyboards.filter(item => item.projectId!==storyboard.projectId)); setModalMessage('Are you sure you want to delete this project? This action is irreversable.'); setIsModalOpen(true)}}>Delete Project</p>
                        </div>
                        </div>
                    </div>
                    <div className="storyboardCard-detail">
                        <p className="edit-storyboard-label" onClick={()=>{setStoryboardState(storyboard); handleNavigation("/Storyboard", navigate)}}>Edit Storyboard</p>
                        <img onClick={()=>{setStoryboardState(storyboard); handleNavigation("/Storyboard", navigate)}} className='edit-icon' src={edit_icon} alt="edit icon"/>
                    </div>         
                    
                </div>
                <div className="storyboardCard-imgs">
                <img alt="First Storyboard Panel" className="storyboardCard-img index1" src={panel1}/>
                <img alt="Second Storyboard Panel" className="storyboardCard-img index2" src={panel2}/>
                <img alt="Third Storyboard Panel" className="storyboardCard-img index3" src={panel3}/>
                </div>
                
            </div>
            )
            return null    
    })
    return (<div className="storyboardList">{storyboardList}</div>)
}

export default ListOfStoryboards

