const ProductDropdown = () => {
    const greyCarrot = require('../Images/greyCarrot.png')
    const productPaths =[['RFP Generator',"https://chatgpt.com/g/g-X0Aulu9jp-nxtvid-rfp-generator"], ["Proposal Generator","https://chatgpt.com/g/g-lhrsqyBL2-nxtvid-proposal-generator"],["Shot List Generator","https://chatgpt.com/g/g-riOc5zEQF-nxtvid-shot-list-generator"], ["Marketplace", "https://nxtvid.io"]]

    const MenuItems = () => {
        const newProductPaths = productPaths.map(item => {
            
            return <a target="_blank" rel="noopener noreferrer" href={item[1]}><p id={item[0] + "-MenuItem"}>{item[0]}</p></a>
        });
        return newProductPaths
    }

    return(
        <div>
            <div className = "product-dropdown" id='product-dropdown'>
            <p>Storyboard Generator</p>
            <img alt="Grey Dropdown Button"className='grey-carrot'src={greyCarrot}/>
                <div className="product-dropdown-content">
                    <MenuItems/>
                </div>
            </div>
        </div>
        
    )
}

export default ProductDropdown