import { jsPDF } from 'jspdf/dist/jspdf.umd.min.js';



const getBase64ImageFromURL = async (imageUrl, doc) => {
    console.log(imageUrl)
    try {
        const res = await fetch(imageUrl); // Fetch the image from the URL
        const blob = await res.blob(); // Convert the response to a Blob

        // Return a promise that resolves with the base64 string
        return new Promise((resolve, reject) => { // Added reject to handle errors
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result); // Resolve the promise with the result
            reader.onerror = reject; // Reject the promise on error
            reader.readAsDataURL(blob); // Read the Blob as Data URL
        });
    } catch (error) {
        console.error('Error fetching the image:', error); // Log any errors
        throw error; // Re-throw the error to be handled by the caller
    }
};

const addImageToPDF = async (imageUrl, x, y, width,height, doc) => {
    try {
        const imageData = await getBase64ImageFromURL(imageUrl, doc);

        // Ensure the image data is valid base64 with correct prefix
        
        
        if(imageData.startsWith('data:image/jpeg')){
            doc.addImage(imageData, 'JPEG', x,y,width,height);
        }
        // Add image to the PDF
        else{
            doc.addImage(imageData, 'PNG', x,y,width,height);
        }
        
    } catch (error) {
        console.error('Failed to add image to PDF:', error);
    }
};


const exportToPDF = (storyboardState) =>{
    const doc = new jsPDF('landscape');
    
    const panelWidth = 88;
    const panelHeight = 176;
    const startX = 10;
    const startY = 20;
    const margin = 6;
    let pageNumber = 1;

    let promiseChain = Promise.resolve();

    doc.setFontSize(20);
    doc.text(storyboardState.projectTitle + " (Page 1)", 10, 15)

    storyboardState.storyboard.forEach((panel, index) =>{
        const itemsPerPage = 3
        const row = Math.floor((index%itemsPerPage)/3)
        const col = index %3 
        const x = startX + col * (panelWidth + margin)
        const y = startY + row * (panelHeight + margin)
        promiseChain = promiseChain.then(()=>{
            if(index!==0 && index%itemsPerPage===0){
                doc.addPage();
                pageNumber+=1
                doc.setFontSize(20);
                doc.text(storyboardState.projectTitle + " (Page " + pageNumber + ")", 10, 15)
            }
            doc.rect(x+1,y+1,panelWidth,panelHeight);
            doc.setFontSize(14);
            doc.text(doc.splitTextToSize(panel.label,80), x + 5, y + 9)
            doc.setFontSize(10);
            doc.text(doc.splitTextToSize("Shot: " + panel.itemPrompt + "\n\nNotes: " + panel.notes, 80), x+5, y+78)
            
            return addImageToPDF(panel.image, x + 5,y+11,80,60, doc)
        })
        
    });

    
    promiseChain.then(()=>{
        doc.save(`${storyboardState.projectTitle}_Storyboard`)
    }).catch(error =>{
        console.error('Error creating PDF:', error)
    })
}

export default exportToPDF