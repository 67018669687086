const placeholderImage = require('../Images/image_generation_placeholder.jpg')

const createNewStoryboard = (storyboards, setStoryboards, setStoryboardState) =>{
    const tempStoryboards = [...storyboards]
    let projectId = tempStoryboards.length + Math.random();
    setStoryboardState({projectTitle:'Untitled Project', projectId:projectId,storyboard:[{image:placeholderImage, itemPrompt:'', selectedCharacter:'None', label:'', notes:'' }, {image:placeholderImage, itemPrompt:'', selectedCharacter:'None', label:'', notes:'' }, {image:placeholderImage, itemPrompt:'', selectedCharacter:'None', label:'', notes:'' }]})
    tempStoryboards.push({projectTitle:'Untitled Project', projectId:projectId,storyboard:[{image:placeholderImage, itemPrompt:'', selectedCharacter:'None', label:'', notes:'' }, {image:placeholderImage, itemPrompt:'', selectedCharacter:'None', label:'', notes:'' }, {image:placeholderImage, itemPrompt:'', selectedCharacter:'None', label:'', notes:'' }]})
    setStoryboards(tempStoryboards)
}

export default createNewStoryboard