const getImageLinks = async(id) =>{
    console.log(id)
    try{
        const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'   
        const response = await fetch(`${API_BASE_URL}/get-generated-images`, {
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({id:id}),
        });
        const result = await response.json();
        return(JSON.parse(JSON.stringify(result.generations_by_pk.generated_images[0].url)))
    } catch(error){
        console.error('Error: ', error)
    }
}

export default getImageLinks