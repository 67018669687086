import "./AlertModal.css"

const AlertModal = ({message, onConfirm}) =>{
    return(
        <div className = 'modal'>
          <p>{message}</p>
          <button onClick={onConfirm}>OK</button>
        </div>
      )
}

export default AlertModal