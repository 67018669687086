const intervalManager = (() =>{
    const intervals = new Set()
    return{
        setInterval(callback,delay){
            const id = setInterval(callback,delay);
            intervals.add(id);
            return id;
        },
        clearInterval(id){
            clearInterval(id)
            intervals.delete(id);
        },
        clearAllIntervals(){
            intervals.forEach(id => clearInterval(id))
            intervals.clear()
        }

    }
})()

export default intervalManager