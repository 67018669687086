import './SignInCard.css'
const PurchaseTokensCard = ({userInfo}) =>{
    const exitButton = require('../Images/exit_button.png')
    const tokensImage = require('../Images/TokensIcon.png')
    return(
        <div className="purchase-tokens-card">
        <div className="sign-up-card-header">
            <h4 className='purchase-tokens-card-title'>Get More <el className='colored-purple'>NXTVid Tokens</el></h4>
            <img alt="Exit Button" className='exit-button' src={exitButton} onClick={()=>{document.getElementsByClassName("purchase-tokens-card")[0].style.display ="none";document.getElementsByClassName("background-shades")[0].style.display ="none"; document.getElementsByClassName('sign-up-notification-message')[0].innerHTML='';document.getElementsByClassName('sign-in-notification-message')[0].innerHTML='';}}/>
            <p className="sign-up-card-sub-title">Select a package that fits your needs. </p>
            <p className='sign-up-notification-message'></p>
        </div>
        <div className='purchase-tokens-packages'>
            <div className='purchase-tokens-package'>
            <div className='package-details-section'>
            <img alt='tokens' className='tokens-image' src={tokensImage}/>
            <div className='package-details'>
            <p className='tokens-amount '>100</p>
            <p className='tokens-name '>NXTVid Tokens</p>
            </div>
            </div>
            <div className='package-purchase-section'>
                <p className='package-price'>$5.00</p>
                <button className='purchase-package-button' onClick={()=>window.open(`https://buy.stripe.com/9AQaG11Gw99v5R6cMP?prefilled_email=${userInfo.email.replace("@","%40")}`,'_blank')}>Buy now</button>
            </div>
            </div>
            <div className='purchase-tokens-package'>
            <div className='package-details-section'>
            <img alt='tokens' className='tokens-image' src={tokensImage}/>
            <div className='package-details'>
            <p className='tokens-amount'>210</p>
            <p className='tokens-name'>NXTVid Tokens</p>
            </div>
            </div>
            <div className='package-purchase-section'>
                <p className='package-price'>$10.00</p>
                <button className='purchase-package-button' onClick={()=>window.open(`https://buy.stripe.com/7sI9BX70Q5XjenCeUV?prefilled_email=${userInfo.email.replace("@","%40")}`,'_blank')}>Buy now</button>
            </div>
            </div>
            <div className='purchase-tokens-package'>
            <div className='package-details-section'>
            <img alt='tokens' className='tokens-image' src={tokensImage}/>
            <div className='package-details'>
            <p className='tokens-amount'>425</p>
            <p className='tokens-name'>NXTVid Tokens</p>
            </div>
            </div>
            <div className='package-purchase-section'>
                <p className='package-price'>$20.00</p>
                <button className='purchase-package-button' onClick={()=>window.open(`https://buy.stripe.com/4gweWhad2clHdjycMO?prefilled_email=${userInfo.email.replace("@","%40")}`,'_blank')}>Buy now</button>
            </div>
            </div>
            <div className='purchase-tokens-package'>
            <div className='package-details-section'>
            <img alt='tokens' className='tokens-image' src={tokensImage}/>
            <div className='package-details'>
            <p className='tokens-amount'>1100</p>
            <p className='tokens-name' >NXTVid Tokens</p>
            </div>
            </div>
            <div className='package-purchase-section'>
                <p className='package-price'>$50.00</p>
                <button className='purchase-package-button' onClick={()=>window.open(`https://buy.stripe.com/00g4hD2KAfxT1AQbII?prefilled_email=${userInfo.email.replace("@","%40")}`,'_blank')}>Buy now</button>
            </div>
            </div>
        </div>
    </div>
    
)
}

export default PurchaseTokensCard