const DropdownMenu = ({id, menuItems, storyboardState, setStoryboardState, index}) => {
    const handleSelect = (option) => {
        console.log(option)
        const tempStoryboardState = {...storyboardState}
        tempStoryboardState.storyboard[index].selectedCharacter = option
        console.log(tempStoryboardState.storyboard[index])
        setStoryboardState(tempStoryboardState)

    }

    const MenuItems = () => {
        let listOfItems = []
        menuItems.forEach(item => {
            listOfItems.push(<p id={item} onClick={(e)=>handleSelect(e.target.id)}>{item}</p>)
        });
        return(
            <div className="dropdown-content">
                {listOfItems}
            </div>
        )
    }

    return(
        <div>
            <div className = "dropdown" id={id}>
                <label for={"dropdown-"+index} className="dropdown-label">Character</label>
                <button id={"dropdown-"+index} className="dropdown-btn">{storyboardState.storyboard[index].selectedCharacter}</button>
                <MenuItems/>
            </div>
        </div>
        
    )
}

export default DropdownMenu